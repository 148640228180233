<template>
    <section class="pa-2 formular">

        <!--SECTION-->
        <v-row no-gutters :class="{ 'rgg-grey' : g_dark(this) }" class="grey lighten-4 rounded mb-4">
            <v-col cols="7" class="pa-3">
                <v-select outlined dense color="gold" ref="cat_sel"
                          :label="g_i18_keys('category')"
                          item-text="alias"
                          item-value="value"
                          :items="select_category"
                          v-model="to_backend.category"
                          hide-details
                          item-color="gold"
                />
            </v-col>
            <v-col cols="5" class="pa-3">
                <v-text-field outlined dense color="gold"
                              :label="g_i18_keys('sort')"
                              v-model="to_backend.sort"
                              hide-details
                />
            </v-col>
        </v-row>

        <!--IMAGE-->
        <v-row no-gutters :class="{ 'rgg-grey' : g_dark(this) }" class="grey lighten-4 rounded mb-1">
            <v-col cols="12" class="pa-3">
                <v-textarea v-model="to_backend.title"
                            :label="g_i18_keys('title')"
                            auto-grow hide-details
                            outlined dense color="gold"
                            rows="1"
                />
            </v-col>
        </v-row>
        <v-row no-gutters :class="{ 'rgg-grey' : g_dark(this) }" class="grey lighten-4 rounded mb-4 pt-2" align="center">
            <v-col cols="12" align="left" class="pl-3">
                <v-btn
                    class="gold mb-3"
                    dark small depressed dense
                    @click="$refs.inputImage.click()"
                >
                    <v-icon small left>mdi-file-import-outline</v-icon>
                    <span v-if="!edit">{{$t('content_management.dialog.type_image')}}</span>
                    <span v-else-if="card_item.file">{{$t('content_management.dialog.type_image_change')}}</span>
                    <span v-else>{{$t('content_management.dialog.type_image')}}</span>
                    <v-icon v-if="file_data" dark right>mdi-check-bold</v-icon>
                </v-btn>
                <input v-show="false" accept="image/jpg,image/jpeg" type="file" ref="inputImage" @change="prepareFile">

            </v-col>

            <v-col cols="12" class="px-3 mb-3" v-if="file_data && change">
                <v-alert dense type="success" icon="mdi-file-upload" border="left" class="font-weight-bold">
                    <v-row no-gutters>
                        <v-col cols="12" sm="8">
                            {{ file_data.get('file').name }}
                        </v-col>
                        <v-col cols="12" sm="4" align="right">
                            {{ g_formatBytes(file_data.get('file').size) }}
                        </v-col>
                    </v-row>
                </v-alert>
                <img :src="getImagePreview" alt="" width="100%" class="rounded">
            </v-col>
            <v-col cols="12" class="px-3 mb-3" v-else-if="edit && card_item.file">
                <!--<v-alert dense text type="info" icon="mdi-file" border="left">
                    <span>{{ card_item.file }}</span>
                </v-alert>-->
                <img :src="getImage" alt="" width="100%" class="rounded">
            </v-col>
            <v-col cols="12" class="px-3 mb-3" v-else-if="edit && !card_item.file">
                <v-alert dense text type="error" icon="mdi-file-alert" border="left">
                    <span>{{ $t('content_management.dialog.edit_file_missing') }}</span>
                </v-alert>
            </v-col>
        </v-row>

        <!--BUTTONS-->
        <v-row no-gutters v-if="!edit">
            <v-col cols="12" align="center">
                <v-btn depressed class="px-8 gold white--text" @click="create_content" :disabled="!file_data">
                    {{ $t('common.create') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="4"></v-col>
            <v-col cols="4" align="center">
                <v-btn depressed color="gold" dark class="px-4" @click="update_content">
                    {{ $t('common.save') }}
                </v-btn>
            </v-col>
            <v-col cols="4" align="right">
                <rgg-confirm :confirm="delete_content" type="delete" message="content_management.dialog.delete_confirm_message" />
            </v-col>
        </v-row>

    </section>
</template>

<script>
import {createContent, deleteContent, updateContent, uploadFile} from "@/api/content";

export default {
    name: "ContentImageDialog",
    props: {
        edit: Boolean,
        id: Number,
        card_item: Object
    },
    data: () => ({
        to_backend: {
            title: '',
            category: 'HomeSeller',
            sort: '0',
            type: 'Image'
        },
        select_category: [],
        file_data: null,
        image_preview: null,
        change: false
    }),
    created() {
        this.select_category = [
            {alias: this.g_i18_filter('homeseller'), value: 'HomeSeller'},
            {alias: this.g_i18_filter('homecustomer'), value: 'HomeCustomer'},
            {alias: this.g_i18_filter('contest'), value: 'Contest'},
            {alias: this.g_i18_filter('career'), value: 'Career'}
        ];

        if(this.edit) {
            let title = this.$t('content_management.dialog.edit_image') +' ID:'+ this.id;
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

            for (const prop of Object.keys(this.to_backend)) {
                this.to_backend[prop] = this.card_item.raw_data[prop];
            }
        }

    },
    computed: {
        getImage() {
            return process.env.VUE_APP_RGG_PORTAL_UX_API_URL + "/content/" + this.card_item.raw_data.category + "/" + this.id + "/data?jwt=" + this.$store.getters.getJWT + "&ts=" + this.$store.getters.getUpdateTimeStamp;
        },
        getImagePreview() {
            return URL.createObjectURL(this.file_data.get('file'));
        }
    },
    methods: {
        create_content() {
            this.$root.$emit('overlay', true);
            createContent(this.to_backend).then( (response) => {
                if(response) {
                    this.upload_file(response.data);
                }
            }).catch( () => {
                this.$root.$emit('overlay', false);
                this.file_data = null;
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'server_error',
                    msg: 'notification.server_error'
                });
            })
        },

        update_content() {
            this.$root.$emit('overlay', true);
            updateContent(this.id, this.to_backend).then( (response) => {
                if(response && this.change) {
                    this.upload_file(this.id);
                } else {
                    this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                }
            })
        },

        delete_content() {
            this.$root.$emit('overlay', true);
            deleteContent(this.id).then( () => {
                this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_deleted'});
            }).catch( () => {
                this.$root.$emit('overlay', false);
                this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'server_error',
                    msg: 'notification.server_error'
                });
            })
        },

        prepareFile() {
            this.change = true;
            let formData = new FormData();
            formData.append('file', this.$refs.inputImage.files[0]);

            this.file_data = formData;
        },

        upload_file(_id) {
            uploadFile(_id, this.file_data).then( () => {
                this.$store.dispatch("updateTimeStamp")
                if(this.edit) {
                    this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                } else {
                    this.$parent.$emit('action_dialog', {func: 'close', data: 'content_updated'});
                }

            }).catch( () => {
                this.$root.$emit('overlay', false);
                this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                this.$parent.$emit('action_dialog', {func: 'close', data: 'content_updated'});
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'server_error',
                    msg: 'notification.server_error'
                });
            })
        },
    },
}
</script>